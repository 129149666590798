import React from "react";
import "./App.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Home from "./views/home";
import PanelDisplay from "./views/panelDisplay";
import PanelRender from "./views/panelRender";

const router = createBrowserRouter(
  [
    {
      path: "/:type/display/:panel",
      element: <PanelRender />,
    },
    {
      path: "/all",
      element: <PanelDisplay type="all" />,
    },
    {
      path: "/geoespacial",
      element: <PanelDisplay type="geoespacial" />,
    },
    {
      path: "/estatistico",
      element: <PanelDisplay type="estatistico" />,
    },
    {
      path: "/",
      element: <Home />,
    },
  ],
  {
    basename: "/app",
  }
);

function App() {
  return <RouterProvider router={router} />;
  // return (
  //   <div className="App">
  //     <iframe
  //       title="Teste"
  //       src="http://observatorio.agropecuaria.inmet.gov.br/relatorio-dinamico/relatorio?ps=20132302&p=agricultura-familiar/dap"
  //       width="100%"
  //       height="100%"
  //     />
  //   </div>
  // );
}

export default App;
