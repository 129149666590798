export default class PanelMapper {
  constructor() {
    this.allPanels = panelData.sort((a, b) => a.title.localeCompare(b.title));
  }

  getEnv() {
    let env = "PROD";
    if (window.location.href.includes("squads")) env = "TESTE";
    if (window.location.href.includes("homolog")) env = "HOMOLOG";
    return env;
  }

  list(query, type) {
    let byType = this.allPanels;
    if (type !== "all") byType = this.allPanels.filter((p) => !!p[type]);

    const env = this.getEnv();
    const byEnv = byType.filter((p) => p.envs.includes(env));

    return byEnv.filter((p) =>
      this.unnacent(p.title).includes(this.unnacent(query))
    );
  }

  getUrl(panelName, type) {
    const key = this.allPanels.find((p) => p.title === panelName)?.[type];
    const env = this.getEnv();
    if (!key) return "";
    let baseUrl = "";
    if (env === "TESTE") {
      if (type === "estatistico")
        baseUrl =
          "http://teste.observatorio.gt4w.com.br/relatorio-dinamico/relatorio?mobile=true&p=";
      if (type === "geoespacial")
        baseUrl =
          "https://squads.geotech4web.com.br/toth/plataforma-geoespacial/#/";
    } else if (env === "HOMOLOG") {
      if (type === "estatistico")
        baseUrl =
          "https://homolog.agropecuaria.inmet.gov.br/relatorio-dinamico/relatorio?mobile=true&p=";
      if (type === "geoespacial")
        baseUrl =
          "https://homolog.agropecuaria.inmet.gov.br/plataforma-geoespacial/#/";
    } else {
      if (type === "estatistico")
        baseUrl =
          "https://observatorio.agropecuaria.inmet.gov.br/relatorio-dinamico/relatorio?mobile=true&p=";
      if (type === "geoespacial")
        baseUrl =
          "https://observatorio.agropecuaria.inmet.gov.br/plataforma-geoespacial/#/";
    }

    return `${baseUrl}${key}`;
  }

  unnacent(input) {
    return input
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toLowerCase();
  }
}

const panelData = [
  {
    title: "Agricultura Familiar",
    icon: require("../assets/imgs/panels/full_agricultura_familiar.png"),
    geoespacial: "AGRICULTURA_FAMILIAR",
    estatistico: "agricultura-familiar/dap",
    envs: ["TESTE", "HOMOLOG", "PROD"],
  },
  {
    title: "Agropecuária Sustentável e Meio Ambiente",
    icon: require("../assets/imgs/panels/full_agro_sustentavel.png"),
    geoespacial: "AGROPECUARIA_SUSTENTAVEL",
    envs: ["TESTE", "HOMOLOG", "PROD"],
    estatistico: "agropecuaria-sustentavel-meio-ambiente/irrigacao",
  },
  {
    title: "Aquicultura",
    icon: require("../assets/imgs/panels/full_aquicultura.png"),
    geoespacial: "AQUICULTURA_E_PESCA",
    envs: ["TESTE", "HOMOLOG", "PROD"],
    estatistico: "aquicultura/engorda",
  },
  {
    title: "Assistência Técnica",
    icon: require("../assets/imgs/panels/full_assistencia_tecnica.png"),
    geoespacial: "ASSISTENCIA_TECNICA",
    envs: ["TESTE", "HOMOLOG", "PROD"],
    estatistico: "assistencia-tecnica/assistentencia-tecnica-visao-geral",
  },
  {
    title: "Assuntos Fundiários",
    icon: require("../assets/imgs/panels/full_assuntos_fundiarios.png"),
    geoespacial: "ASSUNTOS_FUNDIARIOS",
    envs: ["TESTE", "HOMOLOG", "PROD"],
    estatistico: "assuntos-fundiarios/glebas-publicas-federais",
  },
  {
    title: "Comércio Exterior",
    icon: require("../assets/imgs/panels/full_comex.png"),
    envs: ["TESTE", "HOMOLOG", "PROD"],
    geoespacial: null,
    estatistico: "comercio-exterior/agronegocio",
  },
  {
    title: "Crédito Rural",
    icon: require("../assets/imgs/panels/full_credito_rural.png"),
    envs: ["TESTE", "HOMOLOG", "PROD"],
    geoespacial: "CREDITO_RURAL",
    estatistico: "credito-rural/programas",
  },
  {
    title: "Fertilizantes",
    icon: require("../assets/imgs/panels/full_fertilizantes.png"),
    geoespacial: null,
    envs: ["TESTE", "HOMOLOG", "PROD"],
    estatistico: "fertilizantes/visao-geral",
  },
  {
    title: "Indicações Geográficas",
    icon: require("../assets/imgs/panels/full_indicacoes_geograficas.png"),
    geoespacial: "PAINEL_GERAL",
    envs: ["TESTE", "HOMOLOG", "PROD"],
    estatistico: null,
  },
  {
    title: "Recursos Hídricos e Agricultura",
    icon: require("../assets/imgs/panels/full_hidrico.png"),
    geoespacial: "HIDROGRAFIA",
    envs: ["TESTE", "HOMOLOG", "PROD"],
    estatistico: null,
  },
  {
    title: "Destaques do Censo Agropecuário",
    icon: require("../assets/imgs/panels/full_censo.png"),
    geoespacial: "CENSO_AGROPECUARIO",
    envs: ["TESTE", "HOMOLOG", "PROD"],
    estatistico: null,
  },
  {
    title: "Indicadores",
    icon: require("../assets/imgs/panels/full_indicadores.png"),
    geoespacial: null,
    envs: ["TESTE", "HOMOLOG", "PROD"],
    estatistico: "indicadores/valores-brutos-de-producao",
  },
  {
    title: "Pecuária",
    icon: require("../assets/imgs/panels/full_pecuaria.png"),
    geoespacial: "PECUARIA",
    envs: ["TESTE", "HOMOLOG", "PROD"],
    estatistico: "pecuaria/bovinos",
  },
  {
    title: "Produtos Agrícolas",
    icon: require("../assets/imgs/panels/full_prod_agricola.png"),
    geoespacial: "PRODUTOS_AGRICOLAS",
    envs: ["TESTE", "HOMOLOG", "PROD"],
    estatistico: "producao-agricola/algodao",
  },
  {
    title: "PronaSolos",
    envs: ["TESTE", "HOMOLOG", "PROD"],
    icon: require("../assets/imgs/panels/full_pronasolos.png"),
    geoespacial: null,
    estatistico: "pronasolos/visao-geral",
  },
  {
    title: "ZARC",
    icon: require("../assets/imgs/panels/full_zarc.png"),
    geoespacial: "ZARC",
    envs: ["TESTE", "HOMOLOG", "PROD"],
    estatistico: "zarc/visao-geral",
  },
];
